import markdownToHtml from "@/lib/markdownToHtml";
import { basierCirleBold, futuraNextCondensedExtraBold } from "@/styles/fonts";
import theme from "@/styles/theme";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const MarkdownContent = ({ input }) => {
  const [html, setHtml] = useState("");

  useEffect(() => {
    if (!input) {
      return;
    }

    (async () => {
      let newHtml = await markdownToHtml(input);
      newHtml = newHtml.replace(
        `<img src="/uploads`,
        `<img src="${process.env.NEXT_PUBLIC_API_URL}/uploads`,
      );

      setHtml(newHtml);
    })();
  }, [input]);

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: "100",
        "& h1, h2, h3, h4, h5, h6": {
          fontFamily: futuraNextCondensedExtraBold.style.fontFamily,
          textTransform: "uppercase",
          margin: "2rem 0 0 0",
          wordBreak: "break-word",
          hyphens: "auto",
          lineHeight: "1",
        },
        "& h1": {
          fontSize: "2.125rem",
          lineHeight: "1.2",
          "@media (min-width:600px)": {
            fontSize: "4rem",
          },
        },
        "& h2": {
          lineHeight: "1.2",
        },
        "& h3": {
          fontSize: "1.5rem",
          lineHeight: "1.2",
          "@media (min-width:600px)": {
            fontSize: "2.25rem",
          },
        },
        "& h4": {
          fontSize: "1.875rem",
          lineHeight: "1.2",
          "@media (min-width:600px)": {
            fontSize: "1.875rem",
          },
        },
        "& h5": {
          fontSize: "1.875rem",
        },
        "& h6": {
          fontSize: "1.125rem",
          "@media (min-width:600px)": {
            fontSize: "1.375rem",
          },
        },
        "& p": {
          margin: ".5rem 0",
        },
        "& a": {
          transition: "all .2s ease-in-out",
          color: theme.palette.secondary.main,
          textDecoration: "none",
          "&:visited": {
            color: "#7E69EE",
          },
          "&:hover": {
            color: "#7E69EE",
            opacity: ".75",
          },
          "&:active": {
            color: "#7E69EE",
            opacity: "1",
          },
        },
        "& strong": {
          fontWeight: basierCirleBold.style.fontWeight,
          fontFamily: basierCirleBold.style.fontFamily,
        },
        "& img": {
          maxWidth: "100%",
        },
        "& ul": {
          margin: "0 0 1rem 0",
          paddingLeft: "1.5rem",
          listStyleType: "disc",
          "& li::marker": {
            color: grey[500],
          },
          "& li": {
            marginBottom: "1rem",
          },
        },
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: html.replace(
            /href/gi,
            "target='_blank' rel='norefferer noopener' href ",
          ),
        }}
      />
    </Box>
  );
};

MarkdownContent.propTypes = {
  input: PropTypes.string.isRequired,
};

export default MarkdownContent;
